import React, { useState, useEffect } from "react";
import penIcon from "../../assets/images/edit_btn.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  addConsumerPayment,
  contractorPaymentAdd,
  getProjectDetails,
  putConsumerPaymentStatus,
  putMilestoneStatus,
  updateProject,
} from "../../action/request";
import editBtn from "../../assets/images/checkWhite.png";
import moneyIcon from "../../assets/images/plus-white.svg";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import backBtn from "../../assets/images/chevron-left.svg";
import { Loader } from "../loader";
import viewIcon from "../../assets/images/document.svg";
import plusIcon from "../../assets/images/plus-white.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import FileUpload from "../../components/uploadFile/fileUpload";
import { uploadMediaImage } from "../../action/user";

export const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imagesTypes = ["JPG", "PNG", "JPEG"];
  const docTypes = ["PDF", "DOC", "TXT", "DOCX"];

  const [data, setData] = useState([{ projectDetails: { images: [] } }]);
  const [milestoneModel, setMilestoneModel] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(false);
  const [installmentModel, setInstallmentModel] = useState(false);
  const [contractorPaymentModel, setContractorPaymentModel] = useState(false);
  const [allPaymentsPaid, setAllPaymentsPaid] = useState(false);
  const [isLoad, setIsLoading] = useState(false);
  const [paymentLoad, setPaymentLoading] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(6);
  const [consumerTotal, setCosumerTotal] = useState(0);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setLightboxOpen(true);
  };
  const [visibleDocs, setVisibleDocs] = useState(6);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [docArray, setDocArray] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDocModal, setIsOpenDocModal] = useState(false);
  const [measurementModel, setMeasurementModel] = useState(false);

  const fetchProjectDetails = async () => {
    setIsLoading(true);
    const response = await getProjectDetails(id);
    if (response.status) {
      setData(response.data);
      setIsLoading(false);
      editProjectFormik.setFieldValue(
        `measurements[0].title`,
        response?.data?.projectDetails?.measurements[0]?.title
      );
      editProjectFormik.setFieldValue(
        `measurements[0].measure`,
        response?.data?.projectDetails?.measurements[0]?.measure
      );
      setCosumerTotal(
        data?.projectDetails?.estimationAmount?.amount +
          data?.projectDetails?.vatAmount?.amount
      );
    }
  };

  const editMilestone = async (milestoneId) => {
    const payload = {
      milestoneId: milestoneId,
      status: 2,
    };
    const response = await putMilestoneStatus(id, payload);
    if (response.status) {
      setMilestoneModel(false);
      fetchProjectDetails();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const installmentFormik = useFormik({
    initialValues: {
      paymentId: "",
      mode: "",
      status: 2,
    },
    onSubmit: async (values) => {
      const response = await putConsumerPaymentStatus(id, values);

      if (response.status) {
        toast.success(response.message);
        const filter = data?.projectDetails?.payments.every(
          (payment) => payment.status == 2
        );
        setAllPaymentsPaid(filter);
        setInstallmentModel(false);
        fetchProjectDetails();
        installmentFormik.resetForm();
      } else {
        toast.error(response.message);
      }
    },
  });

  const contractorPaymentFormik = useFormik({
    initialValues: {
      mode: "",
      title: "",
      amount: "",
    },
    onSubmit: async (values) => {
      // console.log("val ", values);
      const response = await contractorPaymentAdd(id, values);
      if (response.status) {
        fetchProjectDetails();
        toast.success(response.message);
        setContractorPaymentModel(false);
        contractorPaymentFormik.resetForm();
      } else {
        toast.error(response.message);
      }
    },
  });

  const consumerPaymentFormik = useFormik({
    initialValues: {
      mode: "",
      title: "",
      amount: "",
    },
    onSubmit: async (values) => {
      const response = await addConsumerPayment(id, values);
      if (response.status) {
        fetchProjectDetails();
        toast.success(response.message);
        setInstallmentModel(false);
        consumerPaymentFormik.resetForm();
      } else {
        toast.error(response.message);
      }
    },
  });

  const editProjectFormik = useFormik({
    initialValues: {
      images: [],
      documents: [],
      measurements: [],
    },
    onSubmit: async (values) => {
      let tempImages = [];
      let tempDocs = [];
      tempImages = data.projectDetails?.images;
      tempDocs = data.projectDetails?.documents;

      if (fileDataArray.length > 0) {
        let arr = [];
        await Promise.all(
          fileDataArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!arr[index]) {
                arr[index] = { title: "", url: "" };
              }
              arr[index].url = res.data[0];
              arr[index].title = item.title;
            }
          })
        );
        tempImages.push(...arr);
      }
      if (docArray.length > 0) {
        let arr = [];

        await Promise.all(
          docArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!arr[index]) {
                arr[index] = {
                  title: "",
                  url: "",
                };
              }
              arr[index].url = res.data[0];
              arr[index].title = item.title;
            }
          })
        );
        tempDocs.push(...arr);
      }
      //checking if measurement is empty
      const isEmptyMeasurement = Object.values(values.measurements[0]).every(
        (value) => value !== ""
      );
      if (!isEmptyMeasurement) {
        values.measurements = [];
      }

      values.images = tempImages;
      values.documents = tempDocs;

      console.log("sdasd ", values);
      const response = await updateProject(id, values);
      if (response.status) {
        fetchProjectDetails();
        setIsOpenDocModal(false);
        setIsOpenModal(false);
        setMeasurementModel(false);
        setFileDataArray([]);
        console.log(fileDataArray.length);

        setDocArray([]);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
      editProjectFormik.handleReset();
    },
  });

  // Function to load more images
  const loadMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 6);
  };

  const loadMoreDocs = () => {
    setVisibleDocs((prevVisibleDocs) => prevVisibleDocs + 6);
  };

  useEffect(() => {
    fetchProjectDetails();
  }, [allPaymentsPaid]);

  return isLoad ? (
    <p>
      <Loader />
    </p>
  ) : (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left detail_ttl">
            <img
              className="i_button"
              src={backBtn}
              alt="back"
              onClick={() => {
                // navigate("/requests/approved");
                navigate(-1);
              }}
            />
            Details
          </div>
        </div>
      </div>
      <div className="tab_box"></div>
      <div className="payout_data-content mb-5">
        <div className="row mb-2">
          <div className="col-lg-4">
            <div className="data">
              Request ID: <span>{data?.requestId}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <div className="data">
                Consumer Name: <span>{data?.userDetails?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="data">
              Service: <span>{data?.serviceDetails?.name}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="data">
              Address: <span>{data?.addressDetails?.address}</span>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="data">
              Scheduled Date & Time:{" "}
              <span>{moment(data?.schedule).format("DD-MM-YYYY hh:mm A")}</span>
            </div>
          </div>
        </div>
      </div>
      {data?.contractor ? (
        <>
          <div className="section-heading">Assigned Contractor Details</div>
          <div className="payout_data-content mb-5">
            <div className="row mb-2">
              <div className="col-lg-4">
                <div className="data redirect_span">
                  Contractor Name:{" "}
                  <span
                    className="cursor"
                    onClick={() => {
                      navigate(`/contractors/details/${data?.contractor?._id}`);
                    }}
                  >
                    {data?.contractor?.name}
                  </span>
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <div className="data">
                    Mobile: <span>{data?.contractor?.mobile}</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="data">
                  Business Name:{" "}
                  <span>{data?.contractor?.business?.name ?? ""}</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="data">
                  Address:{" "}
                  <span>{data?.contractor?.contractorAddress?.address}</span>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="data">
                  Location:
                  <span>{data?.contractor?.contractorAddress?.location}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <form action="">
        <div className="section-heading">Project Details</div>

        <div className="payout_data-content mb-5">
          <div className="row mb-4">
            <div className="col-lg-4">
              <div className="data">
                Project Type: <span>{data?.projectDetails?.type}</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="data">
                Work Duration: <span>{data?.projectDetails?.duration}</span>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-4">
            <div className="section-sub-heading mb-3 label-tape">
              <p className="section-sub-heading"> Measurement</p>
              <button
                className="btn_submit active"
                type="button"
                onClick={() => {
                  setMeasurementModel(true);
                }}
              >
                <img className="label-icon-btn edit_icon" src={penIcon} />
                Edit Measurement
              </button>
            </div>
            <div className="data">
              <span>
                {data?.projectDetails?.measurements[0]?.measure ??
                  "No measurement found"}
              </span>
            </div>
          </div>
          {/* <table className="customtable">
            <thead>
              <tr>
                {data?.projectDetails?.measurements.map((measurement) => (
                  <td>{measurement?.title}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {data?.projectDetails?.measurements.map((measurement) => (
                  <td>{measurement?.measure}</td>
                ))}
              </tr>
            </tbody>
          </table> */}

          <div className="row mb-3 mt-4">
            <div className="section-sub-heading mb-3 label-tape">
              <p className="section-sub-heading"> Milestones</p>
            </div>

            <div className="scroll_y">
              <table className="customtable">
                <thead>
                  <tr>
                    <th className="text-center">Title</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoad ? (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) : data?.projectDetails?.milestones.length > 0 ? (
                    data?.projectDetails?.milestones.map((milestone, index) => {
                      let showCompleteButton = true;

                      // Check if any previous milestones have a status not equal to 2
                      if (index > 0) {
                        const previousMilestones =
                          data?.projectDetails?.milestones.slice(0, index);
                        const firstIncompleteMilestone =
                          previousMilestones.find((m) => m.status !== 2);
                        showCompleteButton = !firstIncompleteMilestone;
                      }
                      return (
                        <>
                          <tr>
                            <td className="text-center">
                              {milestone?.name?.name}
                            </td>
                            <td className="text-center">
                              {milestone?.status == 0 ? "Pending" : "Completed"}
                            </td>
                            <td className="text-center">
                              {showCompleteButton &&
                                milestone?.status !== 2 && (
                                  <button
                                    className="mt-3 btn rounded-pill btn-success"
                                    type="button"
                                    onClick={() => {
                                      setMilestoneModel(true);
                                      setSelectedMilestone(milestone._id);
                                    }}
                                  >
                                    <img
                                      className="label-icon-btn"
                                      src={editBtn}
                                    />
                                    Complete This Milestone
                                  </button>
                                )}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan="9" className="text-center">
                          No Milestone available
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {/* {data?.projectDetails?.milestones.map((milestone, index) => {
              let showCompleteButton = true;

              // Check if any previous milestones have a status not equal to 2
              if (index > 0) {
                const previousMilestones =
                  data?.projectDetails?.milestones.slice(0, index);
                const firstIncompleteMilestone = previousMilestones.find(
                  (m) => m.status !== 2
                );
                showCompleteButton = !firstIncompleteMilestone;
              }
              return (
                <>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-split">
                      <div className="data">
                        Name: <span>{milestone?.name?.name}</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-split">
                      <div className="data">
                        Status:{" "}
                        <span>
                          {milestone?.status == 0 ? "Pending" : "Completed"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {showCompleteButton && milestone?.status !== 2 && (
                        <button
                          className="mt-3 btn rounded-pill btn-success"
                          type="button"
                          onClick={() => {
                            setMilestoneModel(true);
                            setSelectedMilestone(milestone._id);
                          }}
                        >
                          <img className="label-icon-btn" src={editBtn} />
                          Complete This Milestone
                        </button>
                      )}
                    </div>
                  </div>
                </>
              );
            })} */}
          </div>

          {/* <div className="row">
            <p className="gallary_heading">Images</p>
            <div className="images_list">
              {data.projectDetails?.images.length > 0 ? (
                data.projectDetails?.images.map((value, index) => {
                  return (
                    <div className="img-prev">
                      <div className="img_div">
                        <img
                          src={process.env.REACT_APP_S3_URL + "/" + value.url}
                          alt="Image Preview"
                        />
                      </div>
                      <p className="img-sub-heading mt-3"> {value.title}</p>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="text-center">No images found</div>
                </>
              )}
            </div>

            <div className="load_more_div mt-3">
              <button className="load-more-btn">Load More</button>
            </div>
          </div> */}

          <div className="row">
            <div className="image_top_Sec d-flex align-center">
              <p className="gallary_heading">Images</p>
              <button
                className="add_btn"
                type="button"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                <img src={plusIcon} />
                Add Image
              </button>
            </div>
            <div className="images_list">
              {data.projectDetails?.images.length > 0 ? (
                data.projectDetails?.images
                  .slice(0, visibleImages)
                  .map((value, index) => {
                    return (
                      <div
                        className="img-prev"
                        key={index}
                        onClick={() => handleImageClick(value)}
                      >
                        <div className="img_div">
                          <img
                            src={
                              process.env.REACT_APP_S3_URL + "/" + value?.url
                            }
                            alt="Image Preview"
                          />
                        </div>
                        <p className="img-sub-heading mt-3">{value?.title}</p>
                      </div>
                    );
                  })
              ) : (
                <div className="w-100 text-center">
                  <div className="text-center">No images available</div>
                </div>
              )}
            </div>

            {data.projectDetails?.images.length > 6 && (
              <div className="load_more_div mt-3">
                <button
                  className="load-more-btn"
                  type="button"
                  onClick={() => {
                    visibleImages > data.projectDetails?.images.length
                      ? setVisibleImages(6)
                      : loadMoreImages();
                  }}
                >
                  {visibleImages > data.projectDetails?.images.length
                    ? "Show Less"
                    : "Load More"}
                </button>
              </div>
            )}
            <Modal
              className="custom-imgprev"
              show={lightboxOpen}
              onHide={() => setLightboxOpen(false)}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="custom-lightbox-body">
                {selectedImage && (
                  <img
                    src={process.env.REACT_APP_S3_URL + "/" + selectedImage.url}
                    alt={selectedImage.title}
                    className="img-fluid"
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>

          <div className="row mt-3">
            <div className="image_top_Sec d-flex align-center mb-3">
              <p className="gallary_heading">Documents</p>
              <button
                className="add_btn"
                type="button"
                onClick={() => {
                  setIsOpenDocModal(true);
                }}
              >
                <img src={plusIcon} />
                Add Document
              </button>
            </div>

            {data.projectDetails?.documents &&
            data.projectDetails?.documents.length > 0 ? (
              data.projectDetails?.documents
                .slice(0, visibleDocs)
                .map((value, index) => {
                  return (
                    <div className="col-sm-2 doc-prev">
                      <a
                        href={process.env.REACT_APP_S3_URL + "/" + value?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={viewIcon} />
                      </a>
                      <p className="img-sub-heading mt-3"> {value?.title}</p>
                    </div>
                  );
                })
            ) : (
              <>
                <div className="text-center">No documents available</div>
              </>
            )}

            {data.projectDetails?.documents?.length > 6 && (
              <div className="load_more_div mt-4">
                <button
                  className="load-more-btn"
                  type="button"
                  onClick={() => {
                    visibleDocs > data.projectDetails?.documents.length
                      ? setVisibleDocs(6)
                      : loadMoreDocs();
                  }}
                >
                  {visibleDocs > data.projectDetails?.documents.length
                    ? "Show Less"
                    : "Load More"}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="section-heading d-flex justify-between label-tape">
          <p className="section-sub-heading">Budget Details</p>

          <div className="d-flex justify-between">
            <div className="badge-success">
              Material Amount:{" "}
              <span>{data?.projectDetails?.materialAmount?.amount}</span>
            </div>
            <div className="badge-warning" style={{ marginLeft: "50px" }}>
              Platform Amount:{" "}
              <span>{data?.projectDetails?.platformAmount?.amount}</span>
            </div>
            <div className="badge-success" style={{ marginLeft: "50px" }}>
              Labour Amount:{" "}
              <span>{data?.projectDetails?.labourAmount?.amount}</span>
            </div>
            <div className="badge-warning" style={{ marginLeft: "50px" }}>
              Vat Amount:{" "}
              <span>{data?.projectDetails?.vatAmount?.amount.toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className="payout_data-content">
          <div className="row">
            <div className="section-heading d-flex justify-between label-tape">
              <p className="section-sub-heading">Consumer Payments</p>
              <div className="badge-success">
                Total Amount:{" "}
                <span>
                  {data?.consumerTotal ? data?.consumerTotal.toFixed(2) : 0}
                </span>
              </div>

              <div className="badge-warning" style={{ marginLeft: "50px" }}>
                Pending Amount: <span>{data?.consumerBalance?.toFixed(2)}</span>
              </div>

              <button
                className="btn_submit active"
                type="button"
                onClick={() => {
                  setInstallmentModel(true);
                }}
                disabled={data?.consumerBalance > 0 ? false : true}
              >
                <img className="label-icon-btn" src={moneyIcon} />
                Add Payment
              </button>
            </div>
            <table className="customtable">
              <thead>
                <tr>
                  <th className="text-center">Installment Title</th>
                  <th className="text-center">Amount</th>
                  <th className="text-center">Payment ID</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Mode</th>
                  <th className="text-center">Payment Date</th>
                  {/* {allPaymentsPaid == false ? (
                    <th className="text-center">Action</th>
                  ) : (
                    ""
                  )} */}
                </tr>
              </thead>
              <tbody>
                {paymentLoad ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : data?.projectDetails?.payments.length > 0 ? (
                  data?.projectDetails?.payments.map((payment, index) => {
                    // let showPaymentMode = true;
                    // // Check if any previous payments have a status not equal to 2
                    // if (index > 0) {
                    //   const previousPayments =
                    //     data?.projectDetails?.payments.slice(0, index);
                    //   const firstUnpaidPayment = previousPayments.find(
                    //     (p) => p.status !== 2
                    //   );
                    //   showPaymentMode = !firstUnpaidPayment;
                    // }
                    return (
                      <tr>
                        <td className="text-center">{payment.title}</td>
                        <td className="text-center">{payment.amount}</td>
                        <td className="text-center">{payment.paymentId}</td>
                        <td className="text-center">
                          {payment.status === 0 ? "Pending" : "Received"}
                        </td>
                        <td className="text-center">{payment?.mode ?? "-"}</td>
                        <td className="text-center">
                          {payment.paymentDate
                            ? moment(payment.paymentDate).format(
                                "DD-MM-YYYY hh:mm A"
                              )
                            : "-"}
                        </td>
                        {/* {!allPaymentsPaid && (
                          <td className="text-center">
                            {showPaymentMode && payment.status === 0 ? (
                              <button
                                className="btn rounded-pill btn-success"
                                type="button"
                                onClick={() => {
                                  installmentFormik.setFieldValue(
                                    "paymentId",
                                    payment._id
                                  );
                                  setInstallmentModel(true);
                                }}
                              >
                                <img
                                  className="label-icon-btn"
                                  src={editBtn}
                                  alt="edit"
                                />
                                Add Payment Mode
                              </button>
                            ) : null}
                          </td>
                        )} */}
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>

          <div className="row mt-3">
            <div className=" label-tape section-heading">
              <p className="section-sub-heading"> Contractor Payments</p>
              <div className="badge-success">
                Total Amount:{" "}
                <span>
                  {data?.projectDetails?.labourAmount?.amount?.toFixed(2)}
                </span>
              </div>
              <div className="badge-warning" style={{ marginLeft: "50px" }}>
                Pending Amount:{" "}
                <span>{data?.contractorBalance?.toFixed(2)}</span>
              </div>
              <button
                className="btn_submit active"
                type="button"
                onClick={() => {
                  setContractorPaymentModel(true);
                }}
                disabled={data?.contractorBalance > 0 ? false : true}
              >
                <img className="label-icon-btn" src={moneyIcon} />
                Add Payment Mode
              </button>
            </div>

            <table className="customtable">
              <thead>
                <tr>
                  <th>Installment Title</th>
                  <th>Amount</th>
                  <th>Payment ID</th>
                  <th>Status</th>
                  <th>Mode</th>
                  <th>Payment Date</th>
                </tr>
              </thead>
              <tbody>
                {data?.projectDetails?.contractorPayments?.length > 0 ? (
                  data?.projectDetails?.contractorPayments.map(
                    (payment, index) => {
                      return (
                        <tr>
                          <td>{payment.title}</td>
                          <td>{payment.amount}</td>
                          <td>{payment.paymentId}</td>
                          <td>{payment.status === 0 ? "Pending" : "Paid"}</td>
                          <td>{payment.mode}</td>
                          <td>
                            {moment(payment.paymentDate).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-center danger">
                        No Paid Payment
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <Modal
        show={milestoneModel}
        onHide={() => {
          setMilestoneModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to complete this milestone?</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setMilestoneModel(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              editMilestone(selectedMilestone);
            }}
          >
            Complete
          </button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={installmentModel}
        onHide={() => {
          installmentFormik.resetForm();
          setInstallmentModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Installment Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={installmentFormik.handleSubmit}>
            <div className="input_field">
              <input
                type="text"
                name="mode"
                className="input"
                value={installmentFormik.values.mode}
                placeholder="Enter payment mode"
                onChange={installmentFormik.handleChange}
                required
              />
            </div>

            <div className="d-flex justify-content-end gap-2 mt-4">
              <button
                className="btn btn-warning rounded-pill"
                type="button"
                onClick={() => {
                  installmentFormik.resetForm();
                  setInstallmentModel(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-success rounded-pill" type="submit">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal> */}

      {/* modal to add contractor payment */}
      <Modal
        show={installmentModel}
        onHide={() => {
          consumerPaymentFormik.resetForm();
          setInstallmentModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Consumer Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={consumerPaymentFormik.handleSubmit}>
            <div className="input-box">
              <div className="input-label">
                Payment Title
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="text"
                  name="title"
                  className="input"
                  value={consumerPaymentFormik.values.title}
                  placeholder="Enter payment title"
                  onChange={consumerPaymentFormik.handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">
                Payment Mode
                <span>*</span>
              </div>
              <div className="input_field">
                <select
                  className="input"
                  name="mode"
                  onChange={consumerPaymentFormik.handleChange}
                  required
                >
                  <option value="">Select Payment Mode</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
            </div>

            <div className="input-box">
              <div className="input-label">
                Payment Amount
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="number"
                  // max={100000}
                  step="any"
                  min={0}
                  name="amount"
                  className="input"
                  value={consumerPaymentFormik.values.amount}
                  placeholder="Enter payment amount"
                  onChange={consumerPaymentFormik.handleChange}
                  required
                />
              </div>
            </div>

            <div className="d-flex justify-content-end gap-2 mt-4">
              <button
                className="btn btn-warning rounded-pill"
                type="button"
                onClick={() => {
                  consumerPaymentFormik.resetForm();
                  setInstallmentModel(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-success rounded-pill" type="submit">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* modal to add contractor payment */}
      <Modal
        show={contractorPaymentModel}
        onHide={() => {
          contractorPaymentFormik.resetForm();
          setContractorPaymentModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Contractor Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={contractorPaymentFormik.handleSubmit}>
            <div className="input-box">
              <div className="input-label">
                Payment Title
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="text"
                  name="title"
                  className="input"
                  value={contractorPaymentFormik.values.title}
                  placeholder="Enter payment title"
                  onChange={contractorPaymentFormik.handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">
                Payment Mode
                <span>*</span>
              </div>
              <div className="input_field">
                {/* <input
                  type="text"
                  name="mode"
                  className="input"
                  value={contractorPaymentFormik.values.mode}
                  placeholder="Enter payment mode"
                  onChange={contractorPaymentFormik.handleChange}
                  required
                /> */}
                <select
                  className="input"
                  name="mode"
                  onChange={contractorPaymentFormik.handleChange}
                  required
                >
                  <option value="">Select Payment Mode</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
            </div>

            <div className="input-box">
              <div className="input-label">
                Payment Amount
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="number"
                  // max={100000}
                  min={0}
                  name="amount"
                  className="input"
                  value={contractorPaymentFormik.values.amount}
                  placeholder="Enter payment amount"
                  onChange={contractorPaymentFormik.handleChange}
                  required
                  step="any"
                />
              </div>
            </div>

            <div className="d-flex justify-content-end gap-2 mt-4">
              <button
                className="btn btn-warning rounded-pill"
                type="button"
                onClick={() => {
                  contractorPaymentFormik.resetForm();
                  setContractorPaymentModel(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-success rounded-pill" type="submit">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* offcanvas to add images */}
      <Offcanvas
        show={isOpenModal}
        onHide={() => {
          setIsOpenModal(false);
          editProjectFormik.handleReset();
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Images</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form action="" onSubmit={editProjectFormik.handleSubmit}>
            <div className="row">
              <FileUpload
                fileDataArray={fileDataArray}
                setFileDataArray={setFileDataArray}
                fileTypes={imagesTypes}
              />
            </div>
            <div>
              <div className="d-flex justify-content-start gap-2 mt-4">
                <button className="btn btn-success rounded-pill" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* offcanvas to add documents */}
      <Offcanvas
        show={isOpenDocModal}
        onHide={() => {
          setIsOpenDocModal(false);
          editProjectFormik.handleReset();
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Documents</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form action="" onSubmit={editProjectFormik.handleSubmit}>
            <div className="row">
              <FileUpload
                fileDataArray={docArray}
                setFileDataArray={setDocArray}
                fileTypes={docTypes}
              />
            </div>
            <div>
              <div className="d-flex justify-content-start gap-2 mt-4">
                <button className="btn btn-success rounded-pill" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* modal to update measurement */}
      <Modal
        show={measurementModel}
        onHide={() => {
          editProjectFormik.resetForm();
          setMeasurementModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={editProjectFormik.handleSubmit}>
            <div className="input-box">
              <div className="input-label">Measurement</div>
              <div className="input_field">
                <textarea
                  placeholder="Title"
                  value={editProjectFormik.values.measurements[0]?.measure}
                  className="input"
                  onChange={(e) =>
                    editProjectFormik.setFieldValue(
                      `measurements[0].measure`,
                      e.target.value
                    )
                  }
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-2 mt-4">
              <button
                className="btn btn-warning rounded-pill"
                type="button"
                onClick={() => {
                  editProjectFormik.resetForm();
                  setMeasurementModel(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-success rounded-pill" type="submit">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
