import React, { useState, useEffect } from "react";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/customPaginantion/paginationView";
import filterImg from "../../assets/images/Up_Down.svg";
import infoIcon from "../../assets/images/info-circle.svg";
import editIcon from "../../assets/images/edit-02.svg";
import trashIcon from "../../assets/images/trash-03.svg";
import CommonFilter from "../../components/CommonFilter/commonFilter";
import {
  createService,
  deleteService,
  editService,
  getServiceDetails,
  fetchParentServices,
} from "../../action/service";
import Modal from "react-bootstrap/Modal";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { uploadMediaImage } from "../../action/user";

export const Services = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [openAddModel, setOpenAddModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [id, setId] = useState("");
  const [hasChild, setHasChild] = useState(false);

  const getServiceList = async (search = "") => {
    setIsLoading(true);
    const response = await fetchParentServices(
      currentPage,
      paginationValue,
      search,
      sort,
      order
    );
    if (response.status) {
      setServiceList(response.data);
      setTotalRecords(response?.data.length);
      setIsLoading(false);
    }
  };

  const addServiceFormik = useFormik({
    initialValues: {
      name: "",
      isPopular: false,
      icon: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const response = await createService(values);
      if (response.status) {
        toast.success(response.message);
        setOpenAddModel(false);
        getServiceList();
        addServiceFormik.handleReset();
      } else {
        toast.error(response.message);
      }
    },
  });

  const editServiceFormik = useFormik({
    initialValues: {
      name: "",
      isPopular: false,
      icon: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const response = await editService(id, values);
      if (response.status) {
        toast.success(response.message);
        setOpenModel(false);
        getServiceList();
        editServiceFormik.handleReset();
      } else {
        toast.error(response.message);
      }
    },
  });

  const uploadImage = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadMediaImage(formData, "service");
    if (res.status) {
      if (type == 1) {
        addServiceFormik.setFieldValue(`icon`, res.data[0]);
      } else {
        editServiceFormik.setFieldValue(`icon`, res.data[0]);
      }
    }
  };

  const fetchDetails = async (serviceId) => {
    const response = await getServiceDetails(serviceId);
    if (response.status) {
      editServiceFormik.setFieldValue("name", response?.data?.name);
      editServiceFormik.setFieldValue("icon", response?.data?.icon);
    }
  };

  const removeService = async () => {
    const response = await deleteService(id);
    if (response.status) {
      toast.success(response.message);
      setOpenDeleteModel(false);
      getServiceList();
    }
  };

  useEffect(() => {
    getServiceList();
  }, [currentPage, paginationValue, sort, order]);

  return (
    <>
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
        }}
        onSubmit={(values) => {
          getServiceList(values.search);
        }}
      />
      <div className="payout_data-head py-2">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Services</div>
          </div>
          <div className="payout_data-right">
            <button
              className="btn_submit"
              onClick={() => {
                setOpenAddModel(true);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div>Icon</div>
              </th>
              <th>
                <div>
                  {" "}
                  Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div>Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {serviceList?.length > 0 ? (
                  serviceList.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td>
                          <img
                            src={
                              process.env.REACT_APP_S3_URL + "/" + item?.icon
                            }
                            alt="Icon"
                            width={50}
                            height={50}
                          />
                        </td>
                        <td className="noWrap">{item?.name}</td>
                        <td>
                          {/* <button> */}
                          <div className="services_btn">
                            <img
                              className="i_button"
                              src={editIcon}
                              onClick={() => {
                                console.log("sasda");
                                fetchDetails(item?._id);
                                setId(item?._id);
                                setOpenModel(true);
                              }}
                            />
                            {/* </button> */}
                            <img
                              className="i_button"
                              src={infoIcon}
                              onClick={() => {
                                navigate(`/services/subservice/${item?._id}`);
                              }}
                            />

                            <img
                              className="i_button"
                              src={trashIcon}
                              onClick={() => {
                                {
                                  item?.hasChild == true
                                    ? toast.error(
                                        "Please delete sub services first"
                                      )
                                    : setOpenDeleteModel(true);
                                  setId(item?._id);
                                }
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords ?? 0}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      </div>

      {/* CANCEL Request model */}
      <Modal
        show={openAddModel}
        onHide={() => {
          setOpenAddModel(false);
          addServiceFormik.handleReset();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addServiceFormik.handleSubmit}>
            <div className="input-box">
              <div className="input-label">
                Name
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  placeholder="Enter name"
                  id="beneficiary"
                  name="name"
                  required={true}
                  maxLength={50}
                  onChange={(e) =>
                    addServiceFormik.setFieldValue(`name`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Image</div>
              <div className="option-card" style={{ display: "flex" }}>
                <ThumbnailPicker
                  width="60%"
                  height="300px"
                  onFileSelect={(file) => {
                    const _URL = window.URL || window.webkitURL;

                    const img = new Image();
                    uploadImage(file, 1);

                    img.src = _URL.createObjectURL(file);
                  }}
                  image={addServiceFormik.values.icon}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end gap-2 mt-4">
              <button className="btn btn-success rounded-pill" type="submit">
                Save
              </button>
              <button
                type="button"
                class="btn btn-primary no-btn"
                onClick={() => {
                  setOpenAddModel(false);
                  addServiceFormik.handleReset();
                }}
              >
                No
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openModel}
        onHide={() => {
          setOpenModel(false);
          editServiceFormik.handleReset();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={editServiceFormik.handleSubmit}>
            <div className="input-box">
              <div className="input-label">
                Name
                <span>*</span>
              </div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  placeholder="Enter name"
                  id="beneficiary"
                  name="name"
                  required={true}
                  maxLength={50}
                  onChange={(e) =>
                    editServiceFormik.setFieldValue(`name`, e.target.value)
                  }
                  value={editServiceFormik.values.name}
                />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Image</div>
              <div className="option-card" style={{ display: "flex" }}>
                <ThumbnailPicker
                  width="60%"
                  height="300px"
                  onFileSelect={(file) => {
                    const _URL = window.URL || window.webkitURL;

                    const img = new Image();
                    uploadImage(file, 2);

                    img.src = _URL.createObjectURL(file);
                  }}
                  image={editServiceFormik.values.icon}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end gap-2 mt-4">
              <button className="btn btn-success yes-btn" type="submit">
                Save
              </button>
              <button
                type="button"
                class="btn btn-primary no-btn"
                onClick={() => {
                  setOpenAddModel(false);
                  editServiceFormik.handleReset();
                }}
              >
                No
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openDeleteModel}
        onHide={() => {
          setOpenDeleteModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this service?</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-secondary yes-btn"
            data-bs-dismiss="modal"
            onClick={() => {
              removeService();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-primary no-btn"
            onClick={() => {
              setOpenDeleteModel(false);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
